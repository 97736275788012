import React from 'react';
import '../styling/Resume.css';

export default function Resume() {
  return (
    <div className="resume-button" id="resume">
      {/* <img
        src="https://res.cloudinary.com/zumariposa/image/upload/v1598494680/portfolio/ZulayScottborghResume_WebDev_dyprjd.pdf"
        alt="zulay-resume"
        className="resume"
      /> */}
    </div>
  );
}
