import React from 'react';
import '../styling/Landing.css';

export default function Landing() {
  return (
    <div>
      <div className="jumbotron mx-2 mb-2">
        <h1 className="title my-4 pb-4 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          Zulay Scottborgh
        </h1>
        <hr className="my-4 mb-2 line" />
        <p className="subtitle">
          Software Engineer | Web Developer | Digital Marketing
        </p>
      </div>
    </div>
  );
}
